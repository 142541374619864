// Import Bootstrap's core SCSS utilities
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";

// Override default variables and add custom themes
:root {
  --bs-primary: #0d6efd; // Default (light theme) primary color
  --bs-secondary: #6c757d;
}

.offcanvas {
  border: none !important;
}

.blur {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

#main, #contentBar, #subNav {
  padding-bottom: 150px !important;
}

[data-bs-theme="light"] {
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-body-bg: #fff;
  background-color: #eaeaea;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #fff;
  --bs-tertiary-bg-rgb: 255, 255, 255;
}
    
[data-bs-theme="dark"] {
  --bs-body-bg-rgb: 10, 10, 10;
  --bs-body-bg: #101010;
  background-color: #000;
}

[data-bs-theme="custom"] {
  --bs-body-bg: #f00;
  --bs-primary: #ff5722; // Custom primary color
  --bs-secondary: #ffc107; // Custom secondary color
}

// Import Bootstrap's base and components at the end