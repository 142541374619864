html, body {
  overscroll-behavior: none;
}
a:hover {
  color: rgba(var(--bs-info-rgb),var(--bs-text-opacity))!important;
}
.breadcrumb {
  --bs-breadcrumb-divider: '»';
}
.help-icon {
  margin: 0.2em;
  opacity: 0.5;
}
.help-icon:hover {
  opacity: 1;
}

a.postBodyLink {
  color: var(--bs-body-color);
}

i.hover { opacity: 0.5; cursor: pointer; }
i.hover:hover {
  opacity: 1;
}


/****************************************************************
/* General Moderation Mask Styles */

.userModerationSettings .dropdown-menu  {
  margin-top: 0.2em !important;
}
.userModerationSettings .d-flex *, .userModerationSettings span {
  transition: all 0.15s ease; /* Smooth transition for background color and scale */
}
.userModerationSettings span.hidden {
  width: 0 !important;
  display: none;
}
.userModerationSettings span.active {
  width: auto;
  display: flex;
}

.moderationHiddenContent {
  display: none;
}

.moderationMaskContent {
  position: relative;
}

/* Ensure .moderationOptions is fully interactive and visible */
.moderationMaskContent .moderationOptions * {
  pointer-events: auto !important; /* Enable interaction */
  opacity: 1 !important; /* Full opacity */
  z-index: 1 !important; /* Ensure it stays on top of other elements */
  opacity: 1 !important; /* Ensure child elements inside moderationOptions are fully opaque */
  filter: none !important; /* Clear any filtering or blurring */
}

/* When unmasked, restore interaction and visibility for all content */
.moderationMaskContent.unMask .postContentWrapper * {
  pointer-events: auto;
  opacity: 1 !important;
}

.postContentWrapper * {
  transition: filter 0.5s ease, opacity 0.5s ease, pointer-events 0s 0.5s; /* Smooth transition */
}
/* Content blur when masked */
.moderationMaskContent:not(.unMask, .moderationOptions) .postContentWrapper * {
  max-height: 60px !important; 
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}
.moderationMaskContent:not(.unMask, .moderationOptions) .postContentWrapper * {
  filter: blur(8px); /* Blur images, videos, etc. */
  pointer-events: none;
  opacity: 0.95; /* Reduce opacity for other content */
}
.openModeration li span.dropdown-item  {
  padding: 0 !important;
  margin: 0 !important;
}

/* ContentBar */
#searchFilters {
  max-height: 50vh !important;
  overflow-y: auto !important;
}
.nav-pills .nav-item .nav-link { 
  /*padding-left: 2px; 
  padding-right: 5px;*/
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}
.action {
  cursor: pointer;
}
.action:hover {
  color: var(--bs-info) !important;
}
/*
.transition {
  transition: all 3s ease !important;
  height: 0 !important;
}
.transition.active {
  height: auto !important;
}*/

iframe {
  flex-shrink: 1;
  display: block;
  background: transparent !important;
  border-radius: 16px !important;
}

.pastnav {
  margin-top: 0px !important;
}
.fixed-top.pastnav .contentForm {
  padding-top: 51px !important;
}
.fixed-top.pastnav {
  backdrop-filter: blur(10px);
  margin: 0 auto;
}
.main-content { 
  padding-top: 54px !important; 
  padding-bottom: 17vh;
}
.main-content .custom { 
  margin-top: -54px !important;
}
.main-content .custom {
  padding-bottom: 17vh;
}

/* Remove padding-bottom from #main if it contains a .custom child */
.main-content:has(.custom) {
  padding-bottom: 0;
}

.ratio.ratio-2x1 { --bs-aspect-ratio: 50%; }
/*********************************************************
/* Cards */
.stats-cards.card, .stats-cards .card-header, .stats-cards .card-body {
  border: 0 !important;
}
/*********************************************************
/* ContentBar */

.contentBar { 
  width: 100% !important; 
  margin: 0 !important; 
  padding: 5px 0;
}

.contentBarPlaceholder {
  height: 0px !important;
}
.custom.contentBarPlaceholder.contentBarPosition {
  margin-bottom: 9rem;
  height: 0px !important;
}
.contentBarSpacer { height: 0 !important; }
.contentBarSpacer.active { height: 8.6em !important; }
.contentBarSpacer.active.search { height: 9.5em !important; }
.contentBar .nav-item .nav-link {
  margin: 0 !important;
}

.modal-dialog, .modal-content { z-index: 10000 !important; }

.feed-carousel {
  min-height: 50vh !important; 
  width: 100% !important;
}
.feed-carousel .slide-content {
  width: 80% !important;
  margin: 0 auto !important;
}
.feed-carousel .carousel-control-next, .feed-carousel .carousel-control-prev {
  width: 10% !important;
  max-height: 70vh !important;
}
.fullscreen-carousel .modal-dialog {
  margin: 0;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}
.fullscreen-carousel .modal-dialog, .fullscreen-carousel .modal-content, .fullscreen-carousel .modal-body {
  background: none;
  border: none;
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}
.fullscreen-carousel .react-transform-wrapper, .fullscreen-carousel .react-transform-component  {
  display: flex !important; 
  align-items: center !important; 
  height: 100vh !important; 
  width: 100% !important;
}
.fullscreen-carousel .slide, .fullscreen-carousel .carousel-inner { padding: 0 !important; display: flex !important; align-items: center !important; }
.fullscreen-carousel .carousel-item { height: 100vh !important; width: 100% !important; }
.fullscreen-carousel .carousel-item img { object-fit: contain !important; }

@media (max-width: 575px) {
  .fixed-top.pastnav .contentForm { padding-top: 60px !important; }
  /*.pastnav { margin-top: 54px !important; }*/
}
/*********************************************************
/* Media Library */
.modal-content.mediaLibrary { max-height: 85vh; }
/*********************************************************
/* Search */
.lookahead {
  position: relative;
  top: 5px;
  zIndex: 100;
}
.navsearch .rbt-input-main { 
  text-align: center; 
  border-radius: 1em; 
}
.navsearch .rbt-input-main:focus-within { 
  backdrop-filter: blur(10px) !important; 
}
.navsearch .rbt-input-hint { text-align: center; display: none; }

.navbar .rbt-input-main.form-control.rbt-input, .menu-search-input {
  background-color: var(--bs-body-bg) !important;
  border-radius: 1em !important;
  color: rgb(var(--bs-text-light)) !important;
  outline: none !important;
}
.rbt-input-hint {
  color: rgb(var(--bs-text-light)) !important;
}
.navbar .rbt-input-main.rbt-input.form-control.focus {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  background-color: var(--bs-tertiary-bg) !important;
  color: rgb(var(--bs-text-light)) !important;
  border-bottom: 1px;
}
/*******************************************************
/* User Lookahead */
.user-input.form-control {
  padding: 0;
  max-height: 39px !important;
}
.rbt .rbt-input-main {
  border: 0;
}

/*******************************************************
/* Panel Override */
@media (max-width: 575px) {
  .layout.offcanvas { width: 100%; margin-top: 55px !important; margin-bottom: 55px !important; }
}
/*******************************************************
/* User Panel */
.offcanvas-body { overflow-x: hidden !important;}
.currency .dropdown-menu {
  width: 100% !important;
  overflow-x: hidden !important;
}

/*******************************************************
/* Menus */
.navbar .badge { 
  font-size: 50% !important; 
}
#navbarSearchInput:focus-within {
  background-color: #000 !important;
  border: 0px !important;
  outline: none !important;
  border-bottom: 1px solid #fff !important;
}
.nav-link {
  cursor: pointer;
}


/* Masonry grid container */
.my-masonry-grid {
  display: flex;
  width: 100%;
}
.my-masonry-grid_column {
  background-clip: padding-box;
  width: 100%;
}
.my-masonry-grid_column > div {
  margin-bottom: 15px; 
}


/*********************************************************
/* Posts */
.postHeader {
  margin: 0; padding: 0; max-width: 100% !important;
}
.postContentContainer {
  padding: 0;
  margin-top: 0.2em;
}

.pollComponent {
  transition: all 0.8s ease;
  overflow: hidden; 
  min-height: 100px;
}
.pollComponent.expanded {
  height: auto; /* Allows full height */
  opacity: 1; /* Fades in when expanded */
}

.pollLegendKey {
  width: 25px !important;
  min-width: 25px !important;
}

.post-image { width: 100%; }
.carousel .react-transform-wrapper, .carousel .react-transform-component { width: 100% !important; }
.carousel .carousel-item img { width: 100% !important; aspect-ratio: 4/3; object-fit: cover; }
/*img.sized { aspect-ratio: 4/3 !important; object-fit: cover; }*/
.step-in { 
   
}
.postPreview {
  overflow: hidden;
  transition: max-height 0.3s ease;
  transition: all 0.3s ease;
  text-wrap: wrap;
  word-wrap: break-word;
}

.ratio-1x2 { aspect-ratio: 2; }
.carousel .imageAspect { aspect-ratio: auto; }

.gridView .postAttachment, .gridView .imageFeedAspect { aspect-ratio: 1 !important; }
.mediaView .imageAspect { aspect-ratio: auto !important; }
.imageFeedAspect { aspect-ratio: auto; }
.imageAspect { aspect-ratio: auto;}
.videoFeedAspect { aspect-ratio: auto; }

.ratio-grid > .imageAspect, .ratio-grid > .imageFeedAspect, .ratio-grid > .videoFeedAspect, .ratio-grid > .carousel .imageAspect, .ratio-grid > .carousel .videoFeedAspect { aspect-ratio: 1 !important;}
.ratio-media > .imageAspect, .ratio-media > .imageFeedAspect, .ratio-media > .videoFeedAspect, .ratio-media > .carousel .imageAspect, .ratio-media > .carousel .videoFeedAspect { aspect-ratio: 4/3 !important;}


.imageCaption {
  background-color: var(--bs-body-color);
  padding: 0.2em 0.5em;
  font-size: 80%;
  color: var(--bs-text-muted);
  margin: 0;
}
.imageCaption ul, .imageCaption p { margin: 0; }
.imageCaption .title { font-weight: bolder; }

.drag-info { display: none !important; }
.drop-zone.drag-over .drag-info { display: inherit !important; }
.drag-info div { border-style: dashed !important; }

.repliesContainer {
  max-width: 100% !important;
}
.repliesWrapper {
  position: relative;
  margin: 0;
  max-width: 100% !important;
}
.repliesWrapper { 
  font-size: 95%;
  max-width: 100% !important;
}
.replyContainer {
  padding: 8px;
  border-radius: 15px;
}
.replyContainer .btn-group {
  border-radius: 15px;
}
.repliesWrapper > div > .commentGutter {
  min-width: 23px !important;
  width: 23px !important;
  margin: 0 !important;
  padding: 0 !important;
  flex-shrink: 0 !important;
}
.repliesContainer.hasComments > .repliesWrapper::before {
  content: "";
  position: relative;
  border-style: solid; /* Ensure the border is solid */
  border-width: 0 0 1px 1px; /* Set border width for bottom and left sides */
  border-color: #666;
  top: 22px;
  margin-left: 0;
  margin-bottom: 2rem;
  margin-right: 0;
  left: 12px; /*; /* Adjust the position to connect with the parent */
  z-index: -1;
}

.custom .repliesContainer.hasComments > .repliesWrapper::before {
  z-index: 0;
}

.card-img-bottom video {
  display: block;
}

/* original */
.repliesContainer.hasComments > .repliesWrapper > .flex-fill > .repliesContainer > .repliesWrapper > .flex-fill > div > .replyContainer::after
 {
  content: "";
  position: absolute;
  border-radius: 0 0 0 15px; /* Adjust the border-radius for the bottom left corner */
  border-style: solid; /* Ensure the border is solid */
  border-width: 0 0 1px 1px; /* Set border width for bottom and left sides */
  border-color: #666;
  margin: 0px !important;
  width: 25px; /* Adjust the width of the curved line */
  height: 15px; /* Adjust the height of the curved line */
  top: 0.5rem; /* Adjust the position of the curved line */
  left: -16px; /*-68px; /* Adjust the position of the curved line */
  z-index: -3 !important;
}
.repliesContainer.hasComments > .repliesWrapper > div > .repliesContainer > .repliesWrapper > .replyContainer::after
 {
  content: "";
  position: absolute;
  border-radius: 0 0 0 15px; /* Adjust the border-radius for the bottom left corner */
  border-style: solid; /* Ensure the border is solid */
  border-width: 0 0 1px 1px; /* Set border width for bottom and left sides */
  border-color: #666;
  margin: 0px !important;
  width: 25px; /* Adjust the width of the curved line */
  height: 15px; /* Adjust the height of the curved line */
  top: 15px; /* Adjust the position of the curved line */
  left: 0px; /*-68px; /* Adjust the position of the curved line */
  z-index: -3 !important;
}



.custom .repliesContainer.hasComments > .repliesWrapper > .flex-fill > .repliesContainer > .repliesWrapper > .flex-fill > div > .replyContainer::after {
  z-index: 0;
}
.custom .avatar_holder {
  z-index: 1 !important;
}
.custom .avatar_holder img.deso_avatar {
  z-index: 10 !important;
}
.repliesContainer.hasComments > .repliesWrapper > .flex-fill > .repliesContainer > .repliesWrapper > .flex-fill > div > .replyContainer:last-child::after {
  visibility: hidden !important;
}

.repliesContainer.hasComments > .replyParent > .replyContainer::after  {
  visibility: hidden !important;
}

@media screen and (max-width: 575px) { 
  .blog-header-image .feature { aspect-ratio: 1 / 1; } 

}
@media screen and (min-width: 576px) { .blog-header-image .feature { aspect-ratio: 1.5 / 1; } }
@media screen and (min-width: 769px) { .blog-header-image .feature { aspect-ratio: 2 / 1; } }
@media screen and (min-width: 1200px) { .blog-header-image .feature { aspect-ratio: 3 / 1; } }
@media screen and (min-width: 1400px) { .blog-header-image .feature { aspect-ratio: 4 / 1; } }
.postAttachment {
  flex-shrink: 1;
  display: block;
}

/*********************************************************
/* Composer */
.modal-autoheight .modal-dialog {
  height: auto !important;
}
.composer .ql-editor.ql-blank::before {
  font-weight: lighter !important;
  color: var(--bs-secondary-color) !important;
  font-style: normal !important;
  opacity: 0.5;
}
.input-formatted > div,
.input-formatted .ql-editor {
  /*border: 1px solid #fff !important;*/
  background-color: var(--bs-dark-bg) !important;
  color: var(--bs-body-bg) !important;
}
.composerCoverImage {
  z-index: -1;
}

.placeholderSelection {
  width: 40px;
  height: 20px;
  object-fit: cover;
}
textarea.inputBody {
  overflow-y: auto !important; /* Add scroll if the content exceeds the max-height */
  resize: none !important; /* Disable manual resizing */
  color: var(--bs-body-color) !important;
  background-color: var(--bs-bg-body);
}

.ql-container {
  max-width: 100% !important;
  overflow-x: hidden; /* Prevent horizontal overflow */
}
.composer .ql-editor { 
  font-size: 120%; 
  margin: 0 !important;
  padding: 1em 1em 0 !important;
}
.ql-editor, .ql-toolbar { 
  font-size: 120%; 
  margin: 0 !important;
  padding: 0;
}
.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  color: var(--bs-body-color) !important;
  margin-bottom: 1em !important; 
  word-break: normal;
}
.ql-editor {
  max-width: 100% !important;
  padding: 0 !important;
}
.postContentContainer h1, .ql-editor h1 { font-size: 160%; color: var(--bs-body-color); }
.postContentContainer h2, .ql-editor h2 { font-size: 140%; color: var(--bs-body-color); }
.postContentContainer h3, .ql-editor h3 { font-size: 130%; color: var(--bs-body-color); }
.postContentContainer h4, .ql-editor h4 { font-size: 120%; color: var(--bs-body-color); }
.postContentContainer h5, .ql-editor h5 { font-size: 110%; color: var(--bs-body-color); }
.postContentContainer h6, .ql-editor h6 { font-size: 100%; color: var(--bs-body-color); }
.ql-editor ul, .ql-editor ol { margin-left: 0 !important; padding-left: 0em !important; padding-bottom: 0;}
.ql-editor li { margin-left: 1.2em; padding-left: 0em !important; list-style-type: disc !important; padding-bottom: 0.5em; }
.ql-editor li::before { padding: none !important; margin: none !important; display: none !important; }
/*
.ql-editor:focus-within { 
  height: 20em; 
}
  */
/* Center images within paragraphs */
.ql-editor p {
  position: relative;
  margin-bottom: 1em;
  padding: 0;
  width: 100%;
  font-size: 105%;
}

/* Style for images inside paragraphs */
.ql-editor img {
  display: block;
  margin: 0 auto 1em;
  max-width: 766px !important;
  width: 100%;
  object-fit: cover;
  height: auto;
  text-align: center;
}

.ql-toolbar, .ql-formats, .ql-snow, .ql-snow .body, .ql-snow p, .ql-stroke, .ql-stroke svg, .ql-picker-label {
  border-color: var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}
.ql-stroke {
  stroke: var(--bs-body-color) !important;
}
.ql-fill {
  fill: var(--bs-body-color) !important;
}
.ql-editor p:empty {
  display: none;
}
/*********************************************************
/* Modals */
.modal .carousel-inner {
  height: 100%;
}

.modal .carousel-item {
  text-align: center;
}

.modal .carousel-item img {
  display: block;
  margin: auto;
}


/**********************************************************
/* Profiles */
.customiseProfile .header { cursor:row-resize; }
.custom {
  min-height: 100vh !important;
}
.profileContent {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
.profile-header {
  position: relative;
  min-height: 5em;
}
.profile-header-noimage {
  position: relative;
}
.profile-header div.feature.reflect {
  width: 100%;
  height: auto;
  position: absolute;
  height: 50px;
  z-index: 100;
}
.profile-header-noimage > div.feature:not(.reflect) {
  width: 100%;
  min-height: 10em;
}
.profile-header-noimage > div.feature.reflect {
  height: 50px !important;
}
.profile-page-content {
  min-height: 50vh;
}
img.feature, .tooltip-inner img {
  width: 100% !important;
  object-fit: cover;
  overflow: hidden; 
  object-position: 0% 100%;
}
img.feature.reflect {
  width: 100% !important; 
  height: 50px !important;
  object-fit: cover;
  overflow: hidden; 
  object-position: 50% 0%;
  transform: scaleY(-1);
}
img.feature.effect-reflect {
  width: 100% !important; 
  height: 5em !important;
  object-fit: cover;
  overflow: hidden; 
  object-position: 0% 100%;
  transform: scaleY(-1);
  z-index: -1 !important;
  -webkit-transform:scaleY(-1);
  
}
.effect-reflect-container {
  position: relative;
  z-index: -1 !important;
  visibility: hidden;
}
.effect-reflect-container::after {
  visibility: visible;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important; 
  height: 100%;
  background-size: cover;
  background-position: bottom;
  transform: scaleY(-1); /* Flip vertically */
  -webkit-transform: scaleY(-1); /* For Safari */
  mix-blend-mode: overlay; /* Adjust as needed for blending */
  mask-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0)); /* Fades from black to transparent from top to bottom */
  -webkit-mask-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0)); /* For Safari */
}
.effect-reflect-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0 !important;
}
.profile-header-background img.feature { aspect-ratio: 0; }
.profile-light  img.feature { aspect-ratio: 0; }
.profile-header-background img.feature.expanded,
.profile-light  img.feature.expanded { 
  aspect-ratio: 0 !important;
}
.custom { background-color: none; }
.custom .nav-link {
  font-weight: lighter;
  transition: all 0.5s ease-in-out;
}
.custom .nav-link.active {
  font-weight: bolder;
  transition: all 0.5s ease-in-out;
}
.custom .profile-page-content .overlay { border-radius: 0; }

/*.custom .postAttachment {
  border-radius: 0 0 1em 1em;
}*/

@media screen and (min-width: 769px) { 
  .profile-header-background img.feature:not(.expanded) { 
    aspect-ratio: 4 / 1; 
  }
}

@media screen and (min-width: 1200px) { 
  .profile-header-background img.feature:not(.expanded) { 
    aspect-ratio: 6 / 1; 
  } 
}

@media screen and (min-width: 1400px) { 
  .profile-header-background img.feature:not(.expanded) { 
    aspect-ratio: 6 / 1; 
  } 
}

.tooltip { z-index: 20000; }
.tooltip-inner { margin: 0; padding: 0;}

.offcanvas.customiseProfile {
  backdrop-filter: blur(10px) !important;
}

/*********************************************************
/* Avatars */
span.deso_avatar, img.deso_avatar {
  border-radius: 50%;
  height: 1.5em !important;
  width: 1.5em !important;
  min-height: 1.5em !important;
  min-width: 1.5em !important;
  max-height: 1.5em !important;
  max-width: 1.5em !important;
  aspect-ratio: 1 !important;
  object-fit: fill;
  object-position: 50% 50%;
  overflow: hidden;
  border: 0.125em solid #fff;
  position: relative;
  text-align: center;
  /*backdrop-filter: blur(10px); 
  -webkit-backdrop-filter: blur(10px);*/
}
.profile-header-background .deso_avatar {
  height: 100px !important;
  width: 100px !important;
  min-height: 100px !important;
  min-width: 100px !important;
  max-height: 100px !important;
  max-width: 100px !important;
  z-index: 10;
}
.profile .light { max-height: 10em; object-fit: cover; object-position: 100%;}
.profileOverlay { z-index: 10; }

img.deso_avatar:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  /* background-image: url('../assets/person-circle.svg'); */
}
.deso_avatar.online {
  border: 0.125em solid #198754;
  box-shadow: 0 0 10px rgba(25, 135, 84, 0.5);

}
.deso_avatar.inactive .deso_avatar.longinactive {
  border: 0.125em solid #dc3545;
  box-shadow: 0 0 10px rgba(220, 53, 69, 0.5);
}
.deso_avatar.large_avatar {
  border-width: 0.20em;
}
i.trustBadge {
  position: absolute;
  font-size: 60%;
  bottom: -0.25em ;
  right: 0%;
  z-index: 0;
}
/**********************************************************
/* Notifications */
.notification-date { 
  font-size: 70%;
}
.two-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.5em; /* Assuming line height of 1.5em */
  line-height: 1em;
}
/**********************************************************
/* Chat */
.chat-thread {
  cursor: pointer;
}
.chat-message-panel {
  
}
.chat-new-message {
  resize: none;
  transition: height 0.3s ease-in-out !important; /* Smooth transition */
  z-index: 1048 !important;
  height: 4em !important; /* Set an initial height */
}

.chat-new-message:focus {
  height: 8.5em !important; /* Target height on focus */
}

.circle {
  width: 50px; /* Set your desired width */
  height: 50px; /* Ensure height matches the width */
  border-radius: 50% !important; /* This will make it a perfect circle */
  overflow: hidden; /* Optional: useful if you’re displaying an image */
  display: inline-block; /* Useful for inline layout */
}
/*********************************************************/
body {
  overflow-y: scroll;
}

@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	/* .navbar .nav-item:hover .nav-link{   } */
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }
}
@media (max-width: 767px) { 
  .border-md-start {       
      border-left: 0 !important;
  }
}
@media (min-width: 767px) { 
  .border-md-0-bottom {       
      border-bottom: 0 !important;
  }
}

.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }

body {
  
}
img { max-width: 100%; }
a {
  text-decoration: none;
}
.inactive { opacity: 20%; }
.truncate-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
          line-clamp: 4; 
  -webkit-box-orient: vertical;
}
.mobilenav .bi {
  font-size: 1.5rem;
  padding: 0 0.5em;
}
.avatar_container {
  height: 150px;
  width: 150px;
}
.creator-background {
  height: 300px;
  opacity: 0.9;
  background-size: cover;
  background-position-y: initial;
  background-attachment: fixed;
  margin-top:0;
  padding-top:0;
}
.creator-profile__avatar {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  border: 2px solid #555;  
  height:100%;
  position: relative;
  width: 100%;
}
.feed-post__avatar-container {
  height: 56px;
  margin-right: 15px;
  width: 56px;
}
.feed-post__avatar {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
  display: block;
  height: 50px;
  width: 50px;
}
.feed-username { margin: 12px 0 15px; }
.feed-username small { font-weight: 100; font-size: 70%; }

.carousel-caption a { color: #aaa !important; }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);   /* Vertically center the controls */
  max-height: 50%;
  z-index: 1; 
}
.carousel-control-prev:hover,
.carousel-control-next:hover {
  filter: invert(100%) !important;
}
.feed-carousel .carousel-control-prev,
.feed-carousel .carousel-control-next {
  filter: invert(100%) !important;
}

.carousel-control-prev-icon, 
.carousel-control-prev-icon {
  z-index: 30090;
}


.card-footer ul.list-inline { display: flex; margin-bottom: 0 !important; width: 100% !important; }

.relatedpost {
    position: absolute;
    border-right: 1px solid darkgrey;
    height: 100%;
    left: 2.22em;
    position: absolute;  
}
.relatedpost .thread { margin-top: -1em; }
.relatedpost .feed-post__avatar-container {position: absolute; margin-left: -1.4em;}

.table img { max-width: 50px; height: auto;}
@media (max-width: 979px) {
  body {
    padding-top: 0;
    margin-top: 0;
  }
}

.md-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
/* WordPress Hacks */
.post-meta-single-top, .post-meta-wrapper, .post-meta-edit-link-wrapper,
div.row.post-meta.small, div.post-content-single h2, header.entry-header, div.inner-header-description { display: none; height: 0; }
.post-content-inner { margin: 0 !important; }
.bg-black { background-color: #000; }
.modal-content.bg-black {
  background-color: #000; 
  border-color: #333;
  color: #fff;
}
.bg-black .close_post_form, .text-light li.nav-item a { color: #999; }
.text-light.li.nav-item a:hover { color: #000; }

.offcanvas.text-light.li { color: #fff; }

.bg-black .CodeMirror { background-color: #000; color: #fff; }
.bg-black .editor-toolbar { background-color: #000; color: #fff;  }
.bg-black .editor-toolbar a.active, .bg-black .editor-toolbar a:hover {
  background: #111 !important;
  border-color: #333 !important;
  color: #fff !important;
}
.bg-black .editor-toolbar a { color: #6c757d !important; }

.bg-white { background-color: #fff; }
.bg-white .CodeMirror { background-color: #fff; color: #000; }
.bg-white .editor-toolbar { background-color: #fff; color: #000;  }
.bg-white .editor-toolbar a.active, .bg-white .editor-toolbar a:hover {
  background: #fcfcfc !important;
  border-color: #95a5a6 !important;
  color: #000 !important;
}
.bg-white .editor-toolbar a { color: #2c3e50 !important; }

.border-black { border-color: #000; }
.border-white { border-color: #fff; }

.bg-dark button.btn-close, .bg-black button.btn-close { color: #ddd !important; }
.bg-dark button.btn-close:hover, .bg-black button.btn-close:hover { color: #fff !important; }
/* Aspect ratios */
.ar-16-9 {aspect-ratio: 16 / 6;}
div.aspect-ratio {
  width: 100%;
  margin:1%;
  padding:20px 0;
  box-sizing: border-box;
  text-align:center;
}
.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
img.imgthumb {
  cursor: pointer;
} 
.dimmable {
  transform: scale(1);
  transition: transform .5s ease;
}
.dimmable.dimmed {
  filter: grayscale(100%);
  transform: scale(0.7);
}
.dimmed:hover {
  filter: grayscale(0);
  transform: scale(1);
}

input[type="radio"].imgthumb:checked + label.imgthumb {
  filter: grayscale(0);
  border: solid 5px #f00;
}

.aspect-wrapper {
	position: relative;
	width: 100%; /* <-- just a default, can be anything. */
}

   .three-by-two.aspect-ratio { padding-bottom: 66.666% }
  .four-by-three.aspect-ratio { padding-bottom: 75% }
.sixteen-by-nine.aspect-ratio { padding-bottom: 56.25% }

.aspect-content {
    position: absolute;
    top: 0;  bottom: 0;  left: 0;  right: 0;
	padding: 0 24px; /* Optional padding for content area */
}

/*****/

/* Basic Container Divider */
.container-spacer {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}
a { cursor: pointer; }

bg-body.bg-black.form-control:focus { background-color: none; }
/* Hashtag form */
.tag{
  display: inline-block;
  pointer-events: none;
  background-color: #0dcaf0 !important;
  color: #000 !important;
  padding: 0.4em 1em 0.5em 0.5em;
  margin: 0.5em 0.5em;
  border-radius: 15px;
  top: 10px;
  height: 2.5em;
}
.tag::before {
  pointer-events: all;
  display: inline-block;
  content: 'x';
  height: 1.5em;
  width: 1.5em;
  padding: 0.2em;
  line-height: 14px;
  text-align: center;
  color: #111111 !important;
  background-color: #0aa2c0 !important;
  cursor: pointer;
  border-radius: 50%;
  margin-top: 0px;
  margin-right: 0.6em;
}

#form_result.hidden { visibility: hidden; display: none; }
.distribution-option {
  cursor: pointer;
}
/* The Interface */

/* Notifications */
.notification {
  margin: 0.5em;
  border-radius: 10px;
  padding: 0.5em;
  cursor: pointer;
}

#ChatHolder {
  width: calc(100vw - 400px) !important;
}
#ChatHolder .deso_avatar { max-width: 50px; }
.chat_message {
  border-radius: 10px;
}
.chat_message .message_header { font-size: 80%; }
.chat-unread {
  display: block;
  width: 15px;
  bottom: 9px;
  position: relative;
  left: -6px;
  margin-bottom: -13px;
}

#chatThreadContent .fixed-bottom {
  width: 100%;
  max-width: inherit;
  padding-left: 15px;
  padding-right: 15px;
}

#chatThreadContent .fixed-bottom .input-group {
  width: 100%;
  max-width: inherit;
}

.offcanvas .offcanvas-header i {
  padding: 0.5em 1em;
  cursor: pointer;
}


.loading {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 100px;
  height: auto;
  aspect-ratio: 1;
  z-index: 99;
}

.avatar_group  { position: relative; margin: 0 0 0 1em; display: inline-flex; flex-direction: row; }
.avatar_group img.deso_avatar { margin-left: -13px; }
.avatar_group img.deso_avatar:hover { margin-left: -13px; z-index: 1000; }

.username {
  margin: 0;
}
.userHandle {
  margin: 0;
  padding: 0;
  font-size: 70%;
  line-height: 70%;
}
.useraction {
  font-size: 70%;
  margin-bottom: 0.5em;
}
.usertime {
  font-size: 70%;
}
#topNav, #bottomNav { z-index: 10000; }
#topNav .deso_avatar { max-width: 1.5em; max-height: 1.5em; }

/*********************************
/* Profile specific */
#user_profile .deso_avatar { max-width: 1.5em; max-height: 1.5em; }
#profileNav .nav-link { border-radius: 0; }
/* a.postlink { } */
.postPreview .collapse {
  max-height: 5em !important;
  display: block;
}
.mediaPreview img:hover, .mediaPreview img:active {
  cursor: pointer;  
  transform: scale(1.25); 
  transition: 500ms;
  z-index: 10000;
}

.searchVideos.single-line-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

.searchVideos.single-line-scroll:active {
  cursor: grabbing; /* Set cursor to grabbing when clicked or touched */
}
.layout.offcanvas {
  margin-top: 51px !important;
}

   

#topNav { height: 51px !important; margin-top: 0px !important; }
@media (max-width: 767px) {
  .layout.offcanvas { margin-top: 55px !important; margin-bottom: 55px !important; }
  .offcanvas.nav { margin-bottom: 55px !important; }
  #topNav { height: 55px !important; }
  #bottomNav { height: 55px !important; margin: 0; padding: 0; }
}
@media (max-width: 991px) {
  .single-line-scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    cursor: grab; /* Set cursor to grab */
    touch-action: pan-y; /* Allow touch panning vertically */
  }
  .single-line-scroll:active {
      cursor: grabbing; /* Set cursor to grabbing when clicked or touched */
      cursor: grab; /* Set cursor to grab */
      touch-action: pan-y; /* Allow touch panning vertically */
  }
}

#deso_interface_wrapper {
    position: fixed;
    bottom: 4em;
    left: 4em;
}

#deso_interface {
    border-radius: 50%;
    overflow: none;
    height: 4em;
    width: 4em;    
    background-color: grey;
    border: 0.2em solid #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 200ms ease-out;
}
#deso_interface:hover {
    box-shadow: 0 4px 8px 0 rgba(35, 173, 255, 0.2), 0 6px 20px 0 rgba(35, 173, 255, 0.19);
    cursor: pointer;  
    transform: scale(1.25); 
}
#deso_interface_wrapper:hover #deso_interface_menu_wrapper{
    display: block;
    left: 2em;
    width: 40em;
    transition: width 180ms;
    transition-timing-function: ease-out;
    transition-delay: 180ms;
    background: linear-gradient(90deg, rgba(9,9,121,1) 0%, rgba(0,212,255,1) 89%, rgba(255,255,255,0.9743084733893558) 100%);
    border: 0.2em solid #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);        
}

#deso_interface_wrapper:hover #deso_interface_menu_wrapper:after {
    content: '';
    width: 25em;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(20deg);
    -ms-transform: skew(20deg);
    transform: skew(20deg);    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);        
}

#deso_interface_menu_wrapper {
    top: 0.2em; z-index: -1; 
    border: 0.2em solid #fff;
    display: block;
    height: 3.6em;
    color: #fff;
    width: 0px;
    left: 2em;
    padding-left: 1em;
    position: absolute;
    overflow: hidden;
    right: 0;
    margin:0;
    transition: width 80ms;    
}

.deso_interface_button {
    border-radius: 50%;
    position: relative;
    height: 2em;
    width: 2em;
    top: 0em;  
    background-color: grey;
    border: 0.1em solid #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 200ms ease-out;
}

.deso_interface_menu {
    width: 100%; z-index: -1; 
    height: 3.1em;
    padding-left: 1.4em;
    overflow: none;
    font-size: 2em;
}
.deso_interface_menu a {
    float: left;
    text-align: center;
    padding: 0.1em 0.5em;
    text-align: center;
    transition: all 0.3s ease;
    color: white;
    cursor: pointer;
}
.deso_interface_menu a:hover { background: rgba(255, 255, 255, 0.6); }
.deso_interface_menu .active { background: rgba(255, 255, 255, 0.9); }
.deso_interface_menu img { height: 1em; width: auto; color: #fff; vertical-align: middle; margin-top: -0.1em; }
#deso_interface_footer {
    height: 1em;
    font-size: 0.5em;
    width: 100%;
    padding-left: 3em;
    font-size: 0.9em;
    position: absolute;
    display: block;
    bottom: 0;
    color: #666;
    background: rgba(255, 255, 255, 0.5);
}

.replies-1 { border-left:none; }
.replies-2 { border-left:2px solid #999; }
.replies-3 { border-left:2px solid #888; }

.avatar-placeholder {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background-color: #dee2e6;
}
i.bi.placeholdericon {
  font-size: 250% !important;
  opacity: 0.1 !important;
}


.nav {
  display: flex;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
}
.nav-underline {
  overflow: auto !important;
}
.horizontal-nav-wrapper.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}
.horizontal-nav-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch !important;
  -webkit-user-select: none !important;
  user-select: none;
  scrollbar-width: none !important; /* Firefox */
}
.horizontal-nav-wrapper::-webkit-scrollbar {
  display: none;
}

/*********************************************************
/* Interactions */

.engagementPanel {
  user-select: none; 
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.reactions-popover, .diamonds-popover {
  position: absolute;
  top: 0.7rem;
  left: 0.6rem;
  font-size: 15px !important;
  line-height: 20px !important;
  padding: 0.2rem;
}

/* Action formatting */
.send_like, .send_reaction, .send_diamond, .send_repost, .send_reply, .share-link,
.send_like a, .send_reaction a, .send_diamond a, .send_repost a, .send_reply a, .share-link a { cursor: pointer; transition: transform .2s; }
.send_like.active, .send_reaction.active { color: darkred !important; }

.reaction { 
  transition: transform .2s; 
  cursor: pointer; 
  opacity: 0.5;
  min-height: 1.5em;
  overflow: visible;
}

.reaction:hover, 
.reaction.touch { 
  opacity: 1 !important; 
}
.reaction.selected { 
  opacity: 0.9 !important;
  /*transform: translateY(0px); */
}
/*
.reaction.selected:hover i::before,
.reaction.selected.touch:hover i::before,
.reaction.touch.selected:hover {
  animation: none !important;
}*/
.reaction.selected:hover i::before {
  animation: none !important;
}
.reaction:hover i::before, 
.reaction.touch i::before,
.reaction.touch i.deletereaction::before {
  animation: wobble 2s ease-in-out infinite !important;
  text-shadow: 0 0 4px #000;
}

.reaction.touch .display { 
  transform: translateY(-55px) !important; 
}
/*
.reaction.selected.touch .display {
  transform: translateY(0px) !important; 
}*/
.deletereaction {
  opacity: 1;
}

:root {
  --animation-start-time: 0s;
}

.send_diamond { 
  transition: transform .2s; 
  cursor: pointer; 
}

.send_diamond:hover, 
.send_diamond.touch { 
  opacity: 1 !important; 
}

.diamondoption {
  min-height: 2em !important;
  transition: transform 0.2s;
  cursor: pointer;
  opacity: 0.5;
}
.diamondoption .display {
  height: 1.5em !important;
}

.diamondoption:hover, 
.diamondoption.touch,
.diamondoption.active { 
  opacity: 1 !important; 
}

.diamondoption.selected { 
  opacity: 0.9 !important;
  transform: translateY(0px); 
}


.diamondoption:hover i::before, 
.diamondoption.touch i::before,
.diamondoption.active i::before,
.diamondoption.touch.selected:hover i::before {
  animation: pulse 2s ease-in-out infinite !important;
  text-shadow: 0 0 4px #000;
}

.diamondoption.touch .display { 
  transform: translateY(-55px) !important; 
}

.diamondoption.selected.touch .display {
}

.diamond-value { 
  font-size: 60%;
  padding: 0 0.05rem;
}
.diamondoption.touch .diamond-value {
  padding: 0.2rem 0.05rem;
}
.diamondoption .morediamonds {
  font-size: 50%;
}

.send_repost.active { color: darkgreen; }
.send_reply.active { color: darkblue; }

.send_like:hover, .send_reaction:hover, .send_diamond:hover, .send_repost:hover, .send_reply:hover, .share-link:hover { transform: scale(1.25); }
.send_like:hover { color: darkred !important; }
/*.send_diamond:hover { color: #0dcaf0; }*/
/*.share-link:hover { color: #0dcaf0; } */

@keyframes wobble {
  0% {
    transform: scale(1.7) translateY(-8px) rotate(0deg);
  }
  5% {
    transform: scale(1.5) translateY(-16px) rotate(0deg);
  }
  8% {
    transform: scale(1.7) translateY(-12px) rotate(0deg);
  }
  12% {
    transform: scale(1.5) translateY(-6px) rotate(0deg);
  }
  16% {
    transform: scale(1.5) translateY(-5px) rotate(0deg);
  }
  20% {
    transform: scale(3) translateY(-5px) rotate(-20deg);
  }
  30% {
    transform: scale(3) translateY(-5px) rotate(20deg);
  }
  40% {
    transform: scale(2.5) translateY(-5px) rotate(-10deg);
  }
  50% {
    transform: scale(3) translateY(-5px) rotate(10deg);
  }
  60% {
    transform: scale(2.5) translateY(-5px) rotate(0deg);
  }
  70% {
    transform: scale(3) translateY(-5px) rotate(-20deg);
  }
  80% {
    transform: scale(2.5) translateY(-5px) rotate(20deg);
  }
  90% {
    transform: scale(3) translateY(-5px) rotate(-10deg);
  }
  100% {
    transform: scale(2.5) translateY(-5px) rotate(10deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.5) translateY(-5px) rotate(0deg);
  }
  15% {
    transform: scale(2.5) translateY(-5px) rotate(0deg);
  }
  30% {
    transform: scale(2) translateY(-5px) rotate(0deg);
  }
  45% {
    transform: scale(2.5) translateY(-5px) rotate(0deg);
  }
  60% {
    transform: scale(2) translateY(-5px) rotate(0deg);
  }
  75% {
    transform: scale(2.5) translateY(-5px) rotate(0deg);
  }
  100% {
    transform: scale(2) translateY(-5px) rotate(0deg);
  }
}

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  /*padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.75); /* Black w/ opacity */
}
.modal-body .carousel-item img {
  height: 80%;
  width: auto;
  object-fit: cover;
  object-position: 50% 50%;
}
.modal.fade {
  display: none;
  z-index: -100;
}
.modal.show {
  display: block;
  z-index: 10000;
}

/****************************************************************
/* Loader Spinner
/***************************************************************/

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  transform: rotate(45deg);
}
.loader::before {
  content: '';  
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: -24px;
  animation: animloader 4s ease infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  animation: animloader2 2s ease infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 0 24px white, 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 0 24px white, 24px 24px white, 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  37% {
    box-shadow: 0 24px white, 24px 24px white, 24px 48px white, 0px 48px rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 24px white, 24px 24px white, 24px 48px white, 0px 48px white;
  }
  62% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px white, 24px 48px white, 0px 48px white;
  }
  75% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px white, 0px 48px white;
  }
  87% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px white;
  }
  100% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
}

@keyframes animloader2 {
  0% {
    transform: translate(0, 0) rotateX(0) rotateY(0);
  }
  25% {
    transform: translate(100%, 0) rotateX(0) rotateY(180deg);
  }
  50% {
    transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
  }
  75% {
    transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
  }
  100% {
    transform: translate(0, 0) rotateX(0) rotateY(360deg);
  }
}

.sky {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20000; /* Ensure it's above everything */
}

.star {
  position: absolute;
  width: 10px;
  height: 10px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.8), transparent); /* Radial gradient to simulate burning effect */
  border-radius: 50%;
  animation: shooting-star 3s linear infinite; /* Adjust the animation duration for a more realistic speed */
  z-index: 20001; /* Make sure stars are above the .sky */
}

.star::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 20%, transparent); /* Add a trail effect */
  border-radius: 50%;
  animation: shooting-star-trail 3s linear infinite;
}

@keyframes shooting-star {
  0% {
    transform: translateX(0) translateY(0) scale(1);
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(-2000px) translateY(2000px) scale(0.2); /* Add a diagonal trail effect */
    opacity: 0;
  }
}

@keyframes shooting-star-trail {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}


/* Generate 50 stars with different sizes and positions */
.star:nth-child(1) { top: 10%; left: 90%; animation-delay: 0s; animation-duration: 2s; width: 3px; height: 3px; }
.star:nth-child(2) { top: 20%; left: 80%; animation-delay: 1s; animation-duration: 2.5s; width: 2px; height: 2px; }
.star:nth-child(3) { top: 30%; left: 70%; animation-delay: 0.5s; animation-duration: 3s; width: 4px; height: 4px; }
.star:nth-child(4) { top: 40%; left: 60%; animation-delay: 1.2s; animation-duration: 2.2s; width: 2px; height: 2px; }
.star:nth-child(5) { top: 50%; left: 50%; animation-delay: 0.8s; animation-duration: 2.7s; width: 3px; height: 3px; }
.star:nth-child(6) { top: 60%; left: 40%; animation-delay: 1.5s; animation-duration: 2.4s; width: 4px; height: 4px; }
.star:nth-child(7) { top: 70%; left: 30%; animation-delay: 0.3s; animation-duration: 3.2s; width: 3px; height: 3px; }
.star:nth-child(8) { top: 80%; left: 20%; animation-delay: 1.8s; animation-duration: 2.9s; width: 2px; height: 2px; }
.star:nth-child(9) { top: 90%; left: 10%; animation-delay: 0.7s; animation-duration: 2.6s; width: 3px; height: 3px; }
.star:nth-child(10) { top: 15%; left: 5%; animation-delay: 1.4s; animation-duration: 2.3s; width: 2px; height: 2px; }
.star:nth-child(11) { top: 25%; left: 15%; animation-delay: 0.9s; animation-duration: 2.1s; width: 4px; height: 4px; }
.star:nth-child(12) { top: 35%; left: 25%; animation-delay: 1.7s; animation-duration: 2.8s; width: 3px; height: 3px; }
.star:nth-child(13) { top: 45%; left: 35%; animation-delay: 0.6s; animation-duration: 2.5s; width: 2px; height: 2px; }
.star:nth-child(14) { top: 55%; left: 45%; animation-delay: 1.3s; animation-duration: 2.2s; width: 3px; height: 3px; }
.star:nth-child(15) { top: 65%; left: 55%; animation-delay: 0.2s; animation-duration: 2.9s; width: 4px; height: 4px; }
.star:nth-child(16) { top: 75%; left: 65%; animation-delay: 1.1s; animation-duration: 2.6s; width: 2px; height: 2px; }
.star:nth-child(17) { top: 85%; left: 75%; animation-delay: 1.9s; animation-duration: 2.3s; width: 3px; height: 3px; }
.star:nth-child(18) { top: 95%; left: 85%; animation-delay: 0.8s; animation-duration: 2s; width: 4px; height: 4px; }
.star:nth-child(19) { top: 10%; left: 95%; animation-delay: 1.5s; animation-duration: 2.7s; width: 3px; height: 3px; }
.star:nth-child(20) { top: 20%; left: 10%; animation-delay: 0.4s; animation-duration: 2.4s; width: 2px; height: 2px; }
.star:nth-child(21) { top: 30%; left: 20%; animation-delay: 1.2s; animation-duration: 2.1s; width: 4px; height: 4px; }
.star:nth-child(22) { top: 40%; left: 30%; animation-delay: 1s; animation-duration: 2.8s; width: 3px; height: 3px; }
.star:nth-child(23) { top: 50%; left: 40%; animation-delay: 0.9s; animation-duration: 2.5s; width: 2px; height: 2px; }
.star:nth-child(24) { top: 60%; left: 50%; animation-delay: 1.6s; animation-duration: 2.2s; width: 3px; height: 3px; }
.star:nth-child(25) { top: 70%; left: 60%; animation-delay: 0.5s; animation-duration: 2.9s; width: 4px; height: 4px; }
.star:nth-child(26) { top: 80%; left: 70%; animation-delay: 1.3s; animation-duration: 2.6s; width: 2px; height: 2px; }
.star:nth-child(27) { top: 90%; left: 80%; animation-delay: 0.2s; animation-duration: 2.3s; width: 3px; height: 3px; }
.star:nth-child(28) { top: 15%; left: 90%; animation-delay: 1.1s; animation-duration: 2s; width: 4px; height: 4px; }
.star:nth-child(29) { top: 25%; left: 15%; animation-delay: 0.8s; animation-duration: 2.7s; width: 3px; height: 3px; }
.star:nth-child(30) { top: 35%; left: 25%; animation-delay: 1.5s; animation-duration: 2.4s; width: 2px; height: 2px; }
.star:nth-child(31) { top: 45%; left: 35%; animation-delay: 0.4s; animation-duration: 2.1s; width: 4px; height: 4px; }
.star:nth-child(32) { top: 55%; left: 45%; animation-delay: 1.2s; animation-duration: 2.8s; width: 3px; height: 3px; }
.star:nth-child(33) { top: 65%; left: 55%; animation-delay: 1s; animation-duration: 2.5s; width: 2px; height: 2px; }
.star:nth-child(34) { top: 75%; left: 65%; animation-delay: 0.9s; animation-duration: 2.2s; width: 3px; height: 3px; }
.star:nth-child(35) { top: 85%; left: 75%; animation-delay: 1.6s; animation-duration: 2.9s; width: 4px; height: 4px; }
.star:nth-child(36) { top: 95%; left: 85%; animation-delay: 0.5s; animation-duration: 2.6s; width: 2px; height: 2px; }
.star:nth-child(37) { top: 10%; left: 95%; animation-delay: 1.3s; animation-duration: 2.3s; width: 3px; height: 3px; }
.star:nth-child(38) { top: 20%; left: 10%; animation-delay: 0.2s; animation-duration: 2s; width: 4px; height: 4px; }
.star:nth-child(39) { top: 30%; left: 20%; animation-delay: 1.1s; animation-duration: 2.7s; width: 3px; height: 3px; }
.star:nth-child(40) { top: 40%; left: 30%; animation-delay: 0.8s; animation-duration: 2.4s; width: 2px; height: 2px; }
.star:nth-child(41) { top: 50%; left: 40%; animation-delay: 1.5s; animation-duration: 2.1s; width: 4px; height: 4px; }
.star:nth-child(42) { top: 60%; left: 50%; animation-delay: 1.2s; animation-duration: 2.8s; width: 3px; height: 3px; }
.star:nth-child(43) { top: 70%; left: 60%; animation-delay: 1s; animation-duration: 2.5s; width: 2px; height: 2px; }
.star:nth-child(44) { top: 80%; left: 70%; animation-delay: 0.9s; animation-duration: 2.2s; width: 3px; height: 3px; }
.star:nth-child(45) { top: 90%; left: 80%; animation-delay: 1.6s; animation-duration: 2.9s; width: 4px; height: 4px; }
.star:nth-child(46) { top: 15%; left: 90%; animation-delay: 0.5s; animation-duration: 2.6s; width: 2px; height: 2px; }
.star:nth-child(47) { top: 25%; left: 15%; animation-delay: 1.3s; animation-duration: 2.3s; width: 3px; height: 3px; }
.star:nth-child(48) { top: 35%; left: 25%; animation-delay: 0.2s; animation-duration: 2s; width: 4px; height: 4px; }
.star:nth-child(49) { top: 45%; left: 35%; animation-delay: 1.1s; animation-duration: 2.7s; width: 3px; height: 3px; }
.star:nth-child(50) { top: 55%; left: 45%; animation-delay: 0.8s; animation-duration: 2.4s; width: 2px; height: 2px; }


